import React, { useState, useEffect } from 'react';
import FilterSection from './components/FilterSection';
import ResultsSection from './components/ResultsSection';
import Login from './components/Login';  // Importando o novo componente de login
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({});
  const [token, setToken] = useState(null);

  // Função para verificar se o token foi passado na URL e armazenar no localStorage
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('token');
    
    if (tokenFromUrl) {
      localStorage.setItem('authToken', tokenFromUrl);  // Armazena o token no localStorage
      setToken(tokenFromUrl);
      window.history.replaceState({}, document.title, window.location.pathname);  // Remove o token da URL
    } else {
      const storedToken = localStorage.getItem('authToken');
      if (storedToken) {
        setToken(storedToken);  // Define o token armazenado
      }
    }
  }, []);

  const handleSearch = (newFilters, newPage = 1) => {
    setFilters(newFilters);
    const queryString = Object.keys(newFilters)
      .map(key => `${key}=${newFilters[key]}`)
      .join('&');

    fetch(`${process.env.REACT_APP_API_URL}/api/results?${queryString}&page=${newPage}&limit=10`, {
      headers: {
        Authorization: `Bearer ${token}`  // Passa o token na requisição
      }
    })
      .then(response => response.json())
      .then(data => {
        setResults(data.results);
        setPage(newPage);
        setTotalPages(data.totalPages);
      })
      .catch(error => console.error('Erro ao buscar resultados:', error));
  };

  // Verifica se o usuário está autenticado (se o token estiver presente)
  if (!token) {
    return <Login />;  // Redireciona para a página de login se o token não existir
  }

  return (
    <div className="container-fluid">
      <header className="bg-primary text-white p-3 mb-4">
        <h1>Hub AI Atos Normativos</h1>
      </header>
      <main>
        <FilterSection onSearch={handleSearch} />
        <ResultsSection 
          results={results} 
          onPageChange={(newPage) => handleSearch(filters, newPage)}
          currentPage={page}
          totalPages={totalPages}
        />
      </main>
    </div>
  );
};

export default App;
