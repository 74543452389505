import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import SelectedFileItem from './SelectedFileItem';

const ResultsSection = ({ results, onPageChange, currentPage, totalPages }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileUrl, setFileUrl] = useState(null);
  const [comparisonResult, setComparisonResult] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [customPrompt, setCustomPrompt] = useState('');
  const [loading, setLoading] = useState(false); // Estado para controlar o spinner

  const handleSelectFile = (file) => {
    setSelectedFiles(prevState => {
      const alreadySelected = prevState.some(selected => selected.Id === file.Id);
      if (alreadySelected) {
        return prevState.filter(selected => selected.Id !== file.Id);
      } else {
        return [...prevState, file];
      }
    });
  };

  const handleRemoveFile = (fileId) => {
    setSelectedFiles(prevState => prevState.filter(file => file.Id !== fileId));
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const arrayBuffer = e.target.result;
            const externalFile = {
                Id: `${Date.now()}`, // Gera um ID único
                Nome: file.name,
                Conteudo: Array.from(new Uint8Array(arrayBuffer)), // Converte ArrayBuffer para Array
                Externo: true,
            };
            setSelectedFiles(prevState => [...prevState, externalFile]);
        };
        reader.readAsArrayBuffer(file); // Ler como ArrayBuffer
    }
};

  const handleCompareFiles = () => {
    if (selectedFiles.length < 2) {
      alert('Selecione pelo menos dois arquivos para comparar.');
      return;
    }

    setLoading(true); // Exibir o spinner

    const promptToSend = customPrompt || 'Compare os textos a seguir e destaque as diferenças';

    fetch(`${process.env.REACT_APP_API_URL}/api/compare`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ files: selectedFiles, prompt: promptToSend }),
    })
      .then(response => response.json())
      .then(data => {
        setComparisonResult(data.content);
        setShowModal(true);
      })
      .catch(error => console.error('Erro ao comparar arquivos:', error))
      .finally(() => setLoading(false)); // Ocultar o spinner
  };

  const handleViewFile = (fileId) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/view/file/${fileId}`)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        setFileUrl(url);
        setShowModal(true);
      })
      .catch(error => console.error('Erro ao buscar o arquivo:', error));
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFileUrl(null);
    setComparisonResult(null);
  };

  return (
    <div className="card" style={{ padding: '20px', backgroundColor: '#f8f9fa' }}>
      <h5 className="card-title">Resultado da Pesquisa</h5>

      <div className="mb-3">
        <label htmlFor="customPrompt" className="form-label">Prompt Personalizado:</label>
        <input
          type="text"
          id="customPrompt"
          className="form-control"
          value={customPrompt}
          onChange={(e) => setCustomPrompt(e.target.value)}
          placeholder="Digite seu prompt personalizado aqui"
          style={{ marginBottom: '15px' }}
        />
      </div>

      <div className="mb-3 d-flex align-items-center">
        <input
          type="file"
          id="fileUpload"
          style={{ display: 'none' }}
          onChange={handleFileUpload}
        />
        <label htmlFor="fileUpload" className="btn btn-success me-2">
          <i className="fas fa-plus"></i> Adicionar Arquivo
        </label>
        <Button
          variant="primary"
          onClick={handleCompareFiles}
          disabled={selectedFiles.length < 2 || loading} // Desativar o botão durante o carregamento
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Comparar Arquivos'
          )}
        </Button>
      </div>

      {selectedFiles.length > 0 && (
        <div className="mb-3">
          <h6>Arquivos Selecionados:</h6>
          <div className="d-flex flex-wrap">
            {selectedFiles.map(file => (
              <SelectedFileItem key={file.Id} file={file} onRemove={handleRemoveFile} />
            ))}
          </div>
        </div>
      )}

      <table className="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th>Número</th>
            <th>Ano</th>
            <th>Tipo</th>
            <th>Entidade</th>
            <th>Publicado</th>
            <th>Ementa</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result, index) => (
            <tr key={index}>
              <td>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => handleSelectFile(result)}
                >
                  {selectedFiles.some(selected => selected.Id === result.Id) ? '-' : '+'}
                </button>
              </td>
              <td>{result.Numero}</td>
              <td>{result.Ano}</td>
              <td>{result.Tipo}</td>
              <td>{result.Entidade}</td>
              <td>{result.Publicado ? 'Sim' : 'Não'}</td>
              <td>{result.Ementa}</td>
              <td>
                <button 
                  className="btn btn-info btn-sm mr-2"
                  onClick={() => handleViewFile(result.Id)}
                >
                  <i className="fas fa-search"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex justify-content-between mt-3">
        <button 
          className="btn btn-secondary" 
          onClick={() => onPageChange(currentPage - 1)} 
          disabled={currentPage <= 1}
        >
          Anterior
        </button>
        <span>Página {currentPage} de {totalPages}</span>
        <button 
          className="btn btn-secondary" 
          onClick={() => onPageChange(currentPage + 1)} 
          disabled={currentPage >= totalPages}
        >
          Próxima
        </button>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{comparisonResult ? 'Resultado da Comparação' : 'Visualização de Arquivo'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {comparisonResult ? (
            <pre>{comparisonResult}</pre>
          ) : fileUrl ? (
            <iframe
              src={fileUrl}
              title="Visualização de Arquivo"
              style={{ width: '100%', height: '600px', border: 'none' }}
            />
          ) : (
            <p>Carregando...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ResultsSection;
