import React from 'react';

const SelectedFileItem = ({ file, onRemove }) => {
  return (
    <div className="position-relative d-flex align-items-center" style={{
      backgroundColor: '#333',
      color: '#fff',
      borderRadius: '10px',
      padding: '10px 15px',
      marginRight: '10px',
      marginBottom: '10px',
      maxWidth: '200px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      position: 'relative'
    }}>
      {/* Botão de remover no lugar do ícone */}
      <button
        type="button"
        className="close"
        style={{
          backgroundColor: '#ff6f61',
          color: '#fff',
          borderRadius: '50%',
          border: 'none',
          width: '17px',
          height: '17px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          marginRight: '10px',
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
        }}
        aria-label="Close"
        onClick={() => onRemove(file.Id)}
      >
        &times;
      </button>

      <span className="file-name" style={{ flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {file.Id}
      </span>
    </div>
  );
};

export default SelectedFileItem;
