import React from 'react';
import logo from '../assets/img/logo.svg';  // Importando a logo
import illustration from '../assets/img/illustration.svg';  // Importando a ilustração

const Login = () => {
  const handleLogin = () => {
    // Redireciona para o backend para fazer o login
    window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/azuread/login`;
  };

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center">
      <div className="row w-100 h-100">
        {/* Seção Esquerda */}
        <div className="col-md-6 d-flex flex-column align-items-center justify-content-center bg-light">
          <h1 className="text-primary mb-4">Hub de AI Atos Normativos!</h1>
          <button className="btn btn-primary btn-lg" onClick={handleLogin}>
            Quero me conectar
          </button>
          <div className="mt-5">
            <img src={logo} alt="Sistema Indústria" className="img-fluid" style={{ maxWidth: '400px' }} />
          </div>
        </div>

        {/* Seção Direita */}
        <div className="col-md-6 d-flex flex-column align-items-center justify-content-center text-white" style={{ backgroundColor: 'rgb(0, 69, 182)' }}>
          <h2 className="mb-4 text-center">Visibilidade operacional, gerenciamento ágil e otimização dos seus processos corporativos.</h2>
          <img src={illustration} alt="Ilustração" className="img-fluid" style={{ maxWidth: '80%' }} />
        </div>
      </div>
    </div>
  );
};

export default Login;
