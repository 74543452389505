import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const FilterSection = ({ onSearch }) => {
  const [entities, setEntities] = useState([]);
  const [filters, setFilters] = useState({
    entidade: '',
    tipo: '',
    anoDe: '',
    anoAte: '',
    numero: '',
    tagBusca: '',
    ementa: '',
    descricao: ''
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/entities`)
      .then(response => response.json())
      .then(data => setEntities(data))
      .catch(error => console.error('Erro ao carregar entidades:', error));
  }, []);

  const handleChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(filters);
  };

  return (
    <div className="card mb-4" style={{ padding: '20px', backgroundColor: '#f8f9fa' }}>
      <h5 className="card-title">Filtros de Pesquisa</h5>
      <form onSubmit={handleSubmit}>
        <table className="table">
          <tbody>
            <tr>
              <td>
                <div className="form-group">
                  <label>Entidade</label>
                  <select name="entidade" className="form-control" onChange={handleChange}>
                    <option value="">Selecione</option>
                    {entities.map((entity) => (
                      <option key={entity.CD_ENTIDADE} value={entity.CD_ENTIDADE}>
                        {entity.SG_ENTIDADE}
                      </option>
                    ))}
                  </select>
                </div>
              </td>
              <td>
                <div className="form-group">
                  <label>Tipo</label>
                  <select name="tipo" className="form-control" onChange={handleChange}>
                    <option value="">Selecione</option>
                    {/* Adicione as opções de tipo conforme necessário */}
                  </select>
                </div>
              </td>
              <td>
                <div className="form-group">
                  <label>Número</label>
                  <input name="numero" type="text" className="form-control" onChange={handleChange} />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-group">
                  <label>Ano (De)</label>
                  <input name="anoDe" type="text" className="form-control" onChange={handleChange} />
                </div>
              </td>
              <td>
                <div className="form-group">
                  <label>Ano (Até)</label>
                  <input name="anoAte" type="text" className="form-control" onChange={handleChange} />
                </div>
              </td>
              <td>
                <div className="form-group">
                  <label>Tag de Busca</label>
                  <input name="tagBusca" type="text" className="form-control" onChange={handleChange} />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-group">
                  <label>Ementa</label>
                  <input name="ementa" type="text" className="form-control" onChange={handleChange} />
                </div>
              </td>
              <td>
                <div className="form-group">
                  <label>Descrição</label>
                  <input name="descricao" type="text" className="form-control" onChange={handleChange} />
                </div>
              </td>
              <td>
                <div className="form-group">
                  <button type="submit" className="btn btn-success mr-2 w-100">Pesquisar</button>
                  <button type="reset" className="btn btn-secondary w-100">Limpar</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default FilterSection;
